
import { defineComponent } from "vue";
import { Form, useForm } from "vee-validate";

import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import { Modal } from "bootstrap";

interface TransportData {
  name: string;
  cuit: string;
}

export default defineComponent({
  name: "new-address-modal",
  components: {
    ElectronicSignature,
    Form,
  },
  data() {
    return {
      requireReason: true,
      requireExtra: true,
      signatureType: "update",
      loading: false,
      visitLevelOptions: [
        {
          value: 0,
          label: "No etiquetar ningún bidón",
        },
        {
          value: 1,
          label: "Solo bidones de primera visita",
        },
        {
          value: 2,
          label: "Etiquetar todos los bidones",
        },
      ],
      userlist: [],
      criticalError: null,
      errors: {},
      entity: {
        id: "",
        name: "",
        legacy_id: "",
        tracking_level_hcg: 0,
        tracking_level_hmg: 0,
        generate_visits_hmg: false,
        generate_visits_hcg: false,
        cuit: "",
        managerId: "Seleccione un encargado",
        electronicSignature: "",
        searchable: false,
        selectable: false,
      },
    };
  },
  methods: {
    triggerSignatureForm() {
      var modalito = new Modal(document.getElementById("sign-modal"));
      modalito.show();
    },
    catchErrors(error) {
      if (error.response.status == 451) {
        return this.triggerSignatureForm();
      }

      this.entity.electronicSignature = "";
      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    getManagers() {
      ApiService.query("index/users", {
        params: { permission: ["manage_transport"] },
      }).then((response) => {
        this.userlist = response.data;
      });
    },
    submitFormWithSignature(signature) {
      this.entity.electronicSignature = signature;
      this.submitForm();
    },

    submitForm() {
      this.errors = {};

      if (this.entity.id) {
        this.signatureType = "update";
        ApiService.update("transports", this.entity.id.toString(), this.entity)
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      } else {
        this.signatureType = "create";
        ApiService.post("transports", this.entity)
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      }
    },
  },
  mounted() {
    this.getManagers();
    if (this.$route.params.id) {
      this.loading = true;
      ApiService.get("transports", this.$route.params.id.toString())
        .then((response) => {
          this.entity.id = response.data.id;
          this.entity.name = response.data.name;
          this.entity.cuit = response.data.cuit;
          this.entity.managerId = response.data.manager_id.toString();
          if (response.data.legacy_id) {
            this.entity.legacy_id = response.data.legacy_id.toString();
          }
          this.entity.tracking_level_hmg = response.data.tracking_level_hmg;
          this.entity.tracking_level_hcg = response.data.tracking_level_hcg;
          this.entity.generate_visits_hcg = response.data.generate_visits_hcg;
          this.entity.generate_visits_hmg = response.data.generate_visits_hmg;
          this.entity.searchable = response.data.searchable;
          this.entity.selectable = response.data.selectable;
          this.loading = false;
        })
        .catch(this.catchErrors);
    }
  },
});
